import React from 'react';
import Layout from 'components/Layout';
import ForgottenEmailForm from 'forms/ForgottenEmailForm';
import { usePageTracking } from 'helpers/usePageTracking';
import { useUser } from 'state/user/state';

const ForgottenEmail: React.FC = () => {
  const metaTitle = 'Forgotten email';

  const [user] = useUser();
  usePageTracking(metaTitle, user?.isLoggedIn === false);

  return (
    <Layout meta={{ meta_title: metaTitle }} pageType="accountArea" hideAccountMenu>
      <ForgottenEmailForm />
    </Layout>
  );
};

export default ForgottenEmail;
