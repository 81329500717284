import { graphql, useStaticQuery } from 'gatsby';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import { Question } from 'types/forms';

export type ForgottenEmailQuestions = {
  firstName: Question;
  lastName: Question;
  dateOfBirth: Question;
  postcode: Question;
};

type CsForgottenEmail = {
  csForgottenEmail: {
    first_name: CsQuestion;
    last_name: CsQuestion;
    date_of_birth: CsQuestion;
    postcode: CsQuestion;
  };
};

const query = graphql`
  query {
    csForgottenEmail {
      first_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      last_name {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      date_of_birth {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      postcode {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
  }
`;

const useForgottenEmailQuestions = (): ForgottenEmailQuestions => {
  const csQuestions = useStaticQuery<CsForgottenEmail>(query);
  const { processQuestion } = useQuestionProcessor();

  return {
    firstName: processQuestion(csQuestions.csForgottenEmail.first_name),
    lastName: processQuestion(csQuestions.csForgottenEmail.last_name),
    dateOfBirth: processQuestion(csQuestions.csForgottenEmail.date_of_birth),
    postcode: processQuestion(csQuestions.csForgottenEmail.postcode),
  };
};

export default useForgottenEmailQuestions;
