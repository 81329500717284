import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { addYearsToDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  dateValueGreaterThanOrEqualTo,
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  matchesRegex,
  required,
  requiredDateValue,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { POSTCODE_REGEX } from 'helpers/regexes';
import { CsErrorsMissingOnly } from 'types/contentStack';

export type ForgottenEmailDetails = {
  firstName: string;
  lastName: string;
  dateOfBirth: DateValue;
  postcode: string;
};

type CsForgottenEmailErrorMessages = {
  csForgottenEmail: {
    first_name: CsErrorsMissingOnly;
    last_name: CsErrorsMissingOnly;
    date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        date_too_early: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        year_too_short: string;
        invalid_date: string;
      };
    };
    postcode: {
      error_messages: {
        missing: string;
        invalid_format: string;
      };
    };
  };
};

const query = graphql`
  query {
    csForgottenEmail {
      first_name {
        error_messages {
          missing
        }
      }
      last_name {
        error_messages {
          missing
        }
      }
      date_of_birth {
        error_messages {
          missing
          date_in_future
          date_too_early
          too_young
          invalid_day
          invalid_month
          year_too_short
          invalid_date
        }
      }
      postcode {
        error_messages {
          missing
          invalid_format
        }
      }
    }
  }
`;

const useForgottenEmailRules = (): ValidationRules<ForgottenEmailDetails> => {
  const errorMessages = useStaticQuery<CsForgottenEmailErrorMessages>(query);

  return {
    firstName: [
      required(errorMessages.csForgottenEmail.first_name.error_messages.missing),
    ],
    lastName: [required(errorMessages.csForgottenEmail.last_name.error_messages.missing)],
    dateOfBirth: [
      requiredDateValue(
        errorMessages.csForgottenEmail.date_of_birth.error_messages.missing
      ),
      dateValueValidDay(
        errorMessages.csForgottenEmail.date_of_birth.error_messages.invalid_day
      ),
      dateValueValidMonth(
        errorMessages.csForgottenEmail.date_of_birth.error_messages.invalid_month
      ),
      dateValueValidYear(
        errorMessages.csForgottenEmail.date_of_birth.error_messages.year_too_short
      ),
      dateValueValid(
        errorMessages.csForgottenEmail.date_of_birth.error_messages.invalid_date
      ),
      dateValueLessThanOrEqualTo(
        new Date(),
        errorMessages.csForgottenEmail.date_of_birth.error_messages.date_in_future
      ),
      dateValueLessThanOrEqualTo(
        addYearsToDate(new Date(), -17),
        errorMessages.csForgottenEmail.date_of_birth.error_messages.too_young
      ),
      dateValueGreaterThanOrEqualTo(
        new Date(1900, 0, 1),
        errorMessages.csForgottenEmail.date_of_birth.error_messages.date_too_early
      ),
    ],
    postcode: [
      required(errorMessages.csForgottenEmail.postcode.error_messages.missing),
      matchesRegex(
        POSTCODE_REGEX,
        errorMessages.csForgottenEmail.postcode.error_messages.invalid_format
      ),
    ],
  };
};

export default useForgottenEmailRules;
