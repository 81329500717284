import { PrimaryCta } from '@rsa-digital/evo-shared-components/components/Cta';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledQuestionField = styled(QuestionField)`
  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

export const RichTextWithModalWithMargin = styled(RichTextWithModal)`
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(6)};
  `}
`;

export const PrimaryCtaWithMargin = styled(PrimaryCta)`
  margin-top: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(1)};
  `}
`;
