import { CoverWanted } from 'api/quote/quoteRequest';
import { getCurrentTier, isTieredQuote, Quote, TierOptions } from 'state/quote/quote';

export const isComprehensiveCover = (coverWanted: CoverWanted): boolean =>
  coverWanted === 'Comprehensive';

export const isAnnualPayment = (quote: Quote): boolean =>
  quote.policy.paymentOption === 'Annually';

export const isVehicleRegistrationMissing = (quote: Quote): boolean =>
  !quote.vehicle.registration;

export const currentTierName = (quote: Quote): string | undefined => {
  if (!isTieredQuote(quote)) {
    return 'non-tiered';
  }

  switch (getCurrentTier(quote)?.name) {
    case TierOptions.Essentials:
      return 'essentials';
    case TierOptions.Standard:
      return 'standard';
    case TierOptions.Extra:
      return 'extra';
    default:
      return undefined;
  }
};
