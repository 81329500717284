import BackButton from '@rsa-digital/evo-shared-components/components/BackButton';
import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { HeroPlain } from '@rsa-digital/evo-shared-components/components/Hero';
import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { dateValueToISOString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { StringField } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { RetrieveEmailRequest } from 'api/account/retrieveEmailRequest';
import accountClient from 'api/accountClient';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import React from 'react';
import NameInput from 'components/NameInput';
import {
  trackButtonClick,
  trackFieldError,
  trackTextInputFocus,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import {
  INPUT_REGEX_ALPHANUMERIC_WITH_SPACE,
  INPUT_REGEX_NAME,
} from 'helpers/inputRegexes';
import logApiError from 'helpers/logApiError';
import { accountRoutes } from 'helpers/routingHelper';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { initialDateValue } from 'state/formData/shared/dateValue';
import { CsHero } from 'types/contentStack';
import useForgottenEmailQuestions from './questions';
import {
  PrimaryCtaWithMargin,
  RichTextWithModalWithMargin,
  StyledQuestionField,
} from './styles';
import useForgottenEmailRules, { ForgottenEmailDetails } from './validation';

export type CsForgottenEmail = {
  csForgottenEmail: {
    hero: CsHero;
    retrieve_email_address_button_text: string;
    retrieve_email_address_button_screenreader_text: string;
    error_page: {
      heading: string;
      subheading: string;
      back_button_text: string;
    };
  };
};

export const query = graphql`
  query {
    csForgottenEmail {
      hero {
        heading
        subheading
      }
      retrieve_email_address_button_text
      retrieve_email_address_button_screenreader_text
      error_page {
        heading
        subheading
        back_button_text
      }
    }
  }
`;

export const initialForgottenEmailDetails: ForgottenEmailDetails = {
  firstName: '',
  lastName: '',
  dateOfBirth: initialDateValue,
  postcode: '',
};

const ForgottenEmailForm: React.FC = () => {
  const rules = useForgottenEmailRules();
  const questions = useForgottenEmailQuestions();
  const forgottenEmailFormFields = useStaticQuery<CsForgottenEmail>(query)
    .csForgottenEmail;

  const { isLoading, requestHandler } = useApiRequestHandler();

  const [forgottenEmailDetails, setForgottenEmailDetails] = React.useState<
    ForgottenEmailDetails
  >(initialForgottenEmailDetails);

  const [hasEmailError, setHasEmailError] = React.useState(false);

  const updateForgottenEmailDetails = (update: ForgottenEmailDetails): void => {
    setForgottenEmailDetails((oldDetails) => ({
      ...oldDetails,
      ...update,
    }));
  };

  const { getError, validateOnSubmit, showValidation } = useValidation(
    forgottenEmailDetails,
    rules,
    trackFieldError
  );

  const retrieveEmailAndMoveNext = async (): Promise<void> => {
    requestHandler(async () => {
      try {
        // We need to cast the type because dateValueToISOString could return undefined,
        // however it won't here because we validate the date before running this code.
        const email = await accountClient.retrieveEmail({
          ...forgottenEmailDetails,
          dateOfBirth: dateValueToISOString(forgottenEmailDetails.dateOfBirth),
        } as RetrieveEmailRequest);
        navigate(accountRoutes.login, { state: email });
      } catch (error) {
        logApiError(error);
        setHasEmailError(true);
      }
    });
  };

  const updateIfMatchRegex = (
    id: StringField<ForgottenEmailDetails>,
    update: string,
    regex: RegExp
  ): void => {
    if (update.match(regex)) {
      updateForgottenEmailDetails({
        ...forgottenEmailDetails,
        [id]: update,
      });
    }
  };

  if (hasEmailError) {
    return (
      <div data-cy="ForgottenEmailError">
        <HeroPlain heading={forgottenEmailFormFields.error_page.heading} />
        <RichTextWithModalWithMargin
          html={forgottenEmailFormFields.error_page.subheading}
        />
        <BackButton onClick={() => setHasEmailError(false)}>
          {forgottenEmailFormFields.error_page.back_button_text}
        </BackButton>
      </div>
    );
  }

  return (
    <>
      <HeroPlain {...forgottenEmailFormFields.hero} />
      {isLoading && <LoadingOverlay loadingMessage="Finding your email" />}
      <form onSubmit={validateOnSubmit(retrieveEmailAndMoveNext, scrollAndTrackError)}>
        <StyledQuestionField
          question={questions.firstName}
          errorText={getError('firstName')}>
          <NameInput
            id="firstName"
            maxLength={60}
            value={forgottenEmailDetails.firstName}
            onChange={(e) =>
              updateIfMatchRegex('firstName', e.target.value, INPUT_REGEX_NAME)
            }
            onBlur={() => showValidation('firstName')}
            onFocus={trackTextInputFocus('firstName')}
          />
        </StyledQuestionField>
        <StyledQuestionField
          question={questions.lastName}
          errorText={getError('lastName')}>
          <NameInput
            id="lastName"
            maxLength={60}
            value={forgottenEmailDetails.lastName}
            onChange={(e) =>
              updateIfMatchRegex('lastName', e.target.value, INPUT_REGEX_NAME)
            }
            onBlur={() => showValidation('lastName')}
            onFocus={trackTextInputFocus('lastName')}
          />
        </StyledQuestionField>
        <StyledQuestionField
          question={questions.dateOfBirth}
          errorText={getError('dateOfBirth')}>
          <DateInput
            id="dateOfBirth"
            value={forgottenEmailDetails.dateOfBirth}
            onChange={(value) =>
              updateForgottenEmailDetails({
                ...forgottenEmailDetails,
                dateOfBirth: value,
              })
            }
            onBlur={() => showValidation('dateOfBirth')}
            onFocus={trackTextInputFocus('dateOfBirth')}
          />
        </StyledQuestionField>
        <StyledQuestionField
          question={questions.postcode}
          errorText={getError('postcode')}>
          <TextInput
            id="postcode"
            maxLength={8}
            value={forgottenEmailDetails.postcode}
            onChange={(e) => {
              if (e.target.value.match(INPUT_REGEX_ALPHANUMERIC_WITH_SPACE)) {
                updateForgottenEmailDetails({
                  ...forgottenEmailDetails,
                  postcode: e.target.value.toUpperCase(),
                });
              }
            }}
            onBlur={() => showValidation('postcode')}
            onFocus={trackTextInputFocus('postcode')}
          />
        </StyledQuestionField>
        <PrimaryCtaWithMargin
          cta={{
            displayText: forgottenEmailFormFields.retrieve_email_address_button_text,
            screenReaderText:
              forgottenEmailFormFields.retrieve_email_address_button_screenreader_text,
            onClick: () =>
              trackButtonClick(
                'submit',
                forgottenEmailFormFields.retrieve_email_address_button_text
              ),
          }}
          type="submit"
        />
      </form>
    </>
  );
};

export default ForgottenEmailForm;
